import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useContext, useState } from "react";
import { api } from "../utils/axios-instance";
import { auth } from "../helper/firebaseClient";
import toast from "react-hot-toast";
import fileContext from "../context/fileContext";

export function RenameFolder({ folder }) {
  const [isOpen, setIsOpen] = useState(false);
  const [newName, setNewName] = useState(folder.name);
  const context = useContext(fileContext);

  console.log({ folder });
  const useRenameFolder = useMutation({
    mutationKey: ["rename-folder", folder.id],
    mutationFn: async () => {
      if (newName.trim() === "") {
        throw new Error("Please enter a valid name");
      }
      if (newName === folder.name) {
        return { message: "No change in name" };
      }
      const token = await auth.currentUser.getIdToken();
      const res = await api.post(`/file/folder/renameFolder/${folder.id}`, {
        name: newName,
        idToken: token,
      });

      if (res.status !== 200) {
        throw new Error(res.data);
      }
      console.log("renameFileMutation", res);
      setNewName("");
      return res.data;
    },
    onError: (err) => {
      console.log("Error in renameFileMutation", err);
      setNewName("");
      toast.error(err?.response?.data?.message || err?.message);
    },
    onSuccess: async () => {
      setIsOpen(false);
      context.setRefreshFolders(true);
    },
  });

  return (
    <div>
      <button onClick={() => setIsOpen(true)}>Rename</button>

      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          style: {
            borderRadius: "5px",
          },
        }}
      >
        <DialogTitle>Rename File</DialogTitle>
        <DialogContent
          style={{
            backgroundColor: "#F7F8FA",
          }}
        >
          {/* {JSON.stringify(file)} */}
          <Input
            autoFocus
            id="rename"
            name="rename"
            type="text"
            value={newName || folder.name}
            onChange={(e) => {
              setNewName(e.target.value);
            }}
            onKeyDown={(e) => {
              e.stopPropagation(); // Prevent MUI or browser shortcuts from taking over
            }}
          />
        </DialogContent>
        <DialogActions sx={{ padding: "10px" }}>
          <button
            className="px-2 py-1 mx-2 rounded-lg shadow-sm border border-gray-300"
            onClick={() => {
              setNewName("");
              setIsOpen(false);
            }}
            color="primary"
          >
            Close
          </button>
          <button
            className={`flex items-center gap-2 disabled:opacity-60 disabled:cursor-not-allowed px-4 py-1 rounded-lg shadow-sm text-white bg-[#5E5ADB] hover:bg-[#6e6bd0da]`}
            onClick={() => useRenameFolder.mutate()}
            disabled={useRenameFolder.isPending}
          >
            Rename
            {useRenameFolder.isPending && (
              <CircularProgress size={20} color="inherit" />
            )}
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

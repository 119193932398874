import React, { useContext, useMemo, useState } from "react";
import { useDarkMode } from "../context/darkModeContext";
import { Dialog, DialogContent, Skeleton, Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";

import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DeleteFileConfirmation from "./DeleteFileConfirmation";

import DoneIcon from "@mui/icons-material/Done";

import fileContext from "../context/fileContext";
import { api } from "../utils/axios-instance";
import { deleteObject, getStorage, ref } from "firebase/storage";
import secureLocalStorage from "react-secure-storage";
import { fileIcons } from "../utils/icon-mimetypes";

// images from public folder
const threeDots = `${process.env.PUBLIC_URL}/assets/threedots.webp`;
const DefaultIcon = `${process.env.PUBLIC_URL}/assets/defaulticon.webp`;
const Danger = `${process.env.PUBLIC_URL}/assets/danger.webp`;

const RecentDeletedFiles = ({ recycleFiles, loading, removeFile }) => {
  const { darkMode } = useDarkMode();

  const context = useContext(fileContext);
  const { anchorEl, setAnchorEl, setRefreshRecycleBin } = context;
  const [menuFile, setMenuFile] = useState({});
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [delLoading, setDelLoading] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [select, setSelect] = useState(false);
  const open = Boolean(anchorEl);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // "success" or "error"
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleDelete = async (file) => {
    let profileData = JSON.parse(secureLocalStorage.getItem("profileData"));
    console.log({ profileData });
    if (
      profileData.id === file.owner ||
      profileData.role_priv === "org_admin"
    ) {
      try {
        const storage = getStorage();
        const fileRef = ref(storage, `files/${profileData.org}/${file.id}`);

        // Delete file from the bucket files collection and restore collection... rejects if any of the promises fails
        await Promise.all([
          api.delete(`/file/delete-file/${file.id}/`),
          api.delete(`/file/restore-file/${file.id}/`),
        ]);
        await deleteObject(fileRef);
      } catch (error) {
        throw new Error(
          "Error occurred while deleting the file: " + error.message
        );
      }
    } else {
      throw new Error("You are not the owner of the file.");
    }
  };

  const deleteFiles = async () => {
    setDelLoading(true);
    try {
      const deletePromises = selectedFiles?.map((file) => handleDelete(file));
      await Promise.all(deletePromises);

      setSnackbarSeverity("success");
      setSnackbarMessage("Files deleted successfully.");
      setSnackbarOpen(true);

      setSelectedFiles([]);

      setTimeout(() => {
        setSelect(false);
        setOpenConfirmation(false);
        setRefreshRecycleBin(true);
      }, 500);
    } catch (error) {
      console.error(error);
      setSnackbarSeverity("error");
      setRefreshRecycleBin(true);
      setSnackbarMessage(error.message || "Error deleting files.");
      setSnackbarOpen(true);
    } finally {
      setDelLoading(false);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMultiFileSelect = (file) => {
    if (selectedFiles.includes(file)) {
      console.log("removing file");
      const filteredFiles = selectedFiles.filter(
        (selectedFile) => selectedFile.id !== file.id
      );
      setSelectedFiles(filteredFiles);
    } else {
      console.log("adding file");
      setSelectedFiles([...selectedFiles, file]);
    }
  };
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const getIconByMimeType = (mimeType) => {
    return fileIcons[mimeType] || DefaultIcon;
  };

  const recycleFileIds = useMemo(() => {
    return new Set(recycleFiles.map((file) => file.fileId));
  }, [recycleFiles]);

  const filteredFiles = useMemo(() => {
    return recycleFiles.filter((file) => recycleFileIds.has(file.id));
  }, [recycleFiles, recycleFileIds]);

  return (
    <div className="space-y-8">
      <div className="flex flex-row justify-end items-center gap-4">
        {!openConfirmation && select && (
          <button
            className="text-white px-6 py-1.5 rounded-md text-center bg-blue-700"
            onClick={() => {
              setSelect(false);
              setSelectedFiles([]);
            }}
          >
            Cancel
          </button>
        )}
        {select &&
          selectedFiles.length > 0 &&
          selectedFiles.length !== filteredFiles.length && (
            <button
              className="text-white px-6 py-1.5 rounded-md text-center bg-red-500"
              onClick={() => {
                setOpenConfirmation(true);
              }}
            >
              Delete selected
            </button>
          )}
        <button
          className="text-white px-6 py-1.5 rounded-md text-center bg-red-500"
          onClick={() => {
            setSelect(true);
            setSelectedFiles(filteredFiles);
            setOpenConfirmation(true);
          }}
        >
          Delete All
        </button>
        <button
          className="text-white px-6 py-1.5 rounded-md text-center bg-blue-700"
          onClick={() => setSelect(true)}
        >
          Select Files
        </button>
      </div>
      <div
        className={` grid grid-cols-5 gap-4 ${darkMode ? "text-gray-200" : "text-gray-600"
          }`}
      >
        {loading
          ? Array.from({ length: 5 }).map((_, index) => (
            <div
              key={index}
              className="border border-gray-200 p-3 rounded-2xl shadow-sm"
            >
              <span className="flex flex-row justify-between items-center px-1 my-1">
                <Skeleton variant="circular" height={22} width={22} />
                <img src={threeDots} alt="..." height={25} width={25} />
              </span>

              <span className="">
                <Skeleton variant="rounded" height={60} width="100%" />
                <span className="font-semibold">
                  <Skeleton height={22} width="100%" />
                </span>
              </span>

              <span>
                <span className="flex flex-row justify-between items-center my-1 px-1">
                  <span className="w-full text-sm text-gray-500 font-light">
                    <Skeleton height={18} width="70%" />
                  </span>
                  <Skeleton variant="circular" height={22} width={22} />
                </span>
              </span>
            </div>
          ))
          : filteredFiles?.map((file, index) => (
            console.log({file}),
            <div
              key={index}
              className={`border border-gray-200 p-3 rounded-[16px] cursor-pointer relative`}
              style={{
                backgroundColor: file.color || `#d5b7ff`,
              }}
              onClick={() => handleMultiFileSelect(file)}
            >
              <div
                className="w-full h-full absolute left-0 top-0 bg-red-500 items-center justify-center rounded-[16px]"
                style={{
                  backgroundColor: "rgba(255, 255, 255, 0.5)",
                  display:
                    selectedFiles.includes(file) && select ? "flex" : "none",
                }}
              >
                <div className="py-3 px-3 rounded-full bg-green-400">
                  <DoneIcon />
                </div>
              </div>
              <span className="flex justify-end items-center">
                <span style={{ fontSize: 20 }} className="flex">
                  <button
                    className=""
                    onClick={(event) => {
                      handleMenuClick(event);
                      setMenuFile(file);
                    }}
                  >
                    <img src={threeDots} height={25} width={25} alt="" />
                  </button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    PaperProps={{
                      style: {
                        border: "1px solid [#111  82626]",
                        boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.1)",
                        borderRadius: "6px",
                      },
                    }}
                  >
                    <MenuItem
                      style={{ padding: "0px 10px", color: "#D1293D" }}
                    >
                      <DeleteFileConfirmation //calling the recycle bin instead of directly deleting
                        fileName={menuFile.fileName}
                        owner={menuFile.owner}
                        id={menuFile.id}
                        remove={removeFile}
                        currVersion={menuFile?.currVersion}
                        versions={menuFile?.versions}
                      />
                    </MenuItem>
                  </Menu>
                </span>
              </span>

              <div>
                <span className="flex justify-center items-center">
                  <img
                    src={getIconByMimeType(file.mimeType)}
                    alt="File Preview"
                    className="rounded-md my-4"
                  />
                </span>
                <span>
                  <h5 className="font-semibold line-clamp-1 text-gray-700 text-sm mb-1">
                    {file.fileName?.split("_TS=")[0]}
                  </h5>
                  <span className="flex flex-row justify-between items-center">
                    <span>
                      <h6 className="font-semibold">
                        <span className="text-xs font-bold text-[10px] text-[#676767]">
                          {file.fileSize}
                        </span>
                      </h6>
                    </span>

                    <Avatar
                      src={file.profilePic}
                      alt="owner pic"
                      sx={{
                        width: 24,
                        height: 24,
                        border: "1px solid silver",
                      }}
                      className={`${darkMode && "border border-white "}`}
                    />
                  </span>
                </span>
              </div>
            </div>
          ))}
      </div>
      {openConfirmation && (
        <Dialog
          open={openConfirmation}
          onClose={() => {
            setSelect(false);
            setSelectedFiles([]);
            setOpenConfirmation(false);
          }}
          PaperProps={{
            style: {
              borderRadius: "5px",
              maxWidth: "80%",
              width: "auto",
              maxHeight: "80%",
            },
          }}
          maxWidth="lg"
          fullWidth
        >
          <DialogContent
            style={{
              backgroundColor: "#FEF2F2",
            }}
          >
            <div className="my-2 p-3 flex flex-col justify-center items-center gap-6">
              <img src={Danger} alt="." />
              <h2 className="text-2xl font-semibold text-gray-700">
                Are you sure?
              </h2>
              <div className="text-center">
                <p>This action will delete the following files</p>
                <ul className="my-8">
                  {selectedFiles.map((file) => (
                    <li key={file.id} className="font-bold">
                      {file?.fileName}
                    </li>
                  ))}
                </ul>
                <p>You won't be able to revert this action!</p>
              </div>

              <div className="flex flex-row justify-center items-center gap-2">
                <button
                  className="px-4 py-1 rounded-lg shadow-sm bg-red-500 text-white"
                  onClick={deleteFiles}
                >
                  {delLoading ? "deleting..." : "Confirm"}
                </button>
                <button
                  className="px-4 py-1 mx-2 rounded-lg shadow-sm border border-gray-300"
                  onClick={() => {
                    setSelect(false);
                    setSelectedFiles([]);
                    setOpenConfirmation(false);
                  }}
                  color="primary"
                >
                  Cancel
                </button>
              </div>

              <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
              >
                <MuiAlert
                  elevation={6}
                  variant="filled"
                  onClose={handleSnackbarClose}
                  severity={snackbarSeverity}
                >
                  {snackbarMessage}
                </MuiAlert>
              </Snackbar>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

export default RecentDeletedFiles;

import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import FileView from "./FileView";
import { useDarkMode } from "../context/darkModeContext";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  Skeleton,
  TextField,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import FileInfo from "./FileInfo";
import FileShare from "./FileShare";
import DeleteFileConfirmation from "./DeleteFileConfirmation";

import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import DoneIcon from "@mui/icons-material/Done";
import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";
import PushPinIcon from "@mui/icons-material/PushPin";

import fileContext from "../context/fileContext";
import { api } from "../utils/axios-instance";
import useAuth from "../stores/authStore";
import toast, { Toaster } from "react-hot-toast";
import RecycleBinConfirmation from "./RecycleBinConformation";
import { RenameFile } from "./RenameFile";
import { fileIcons } from "../utils/icon-mimetypes";
import { SetPassword } from "./dialogs/SetPassword";
import { useMutation } from "@tanstack/react-query";
import { auth } from "../helper/firebaseClient";
import FileBg from "../generators/fileBGGenerator";
import { ShowVersions } from "./dialogs/ShowVersions";

// images from public folder
const threeDots = `${process.env.PUBLIC_URL}/assets/threedots.webp`;
const DefaultIcon = `${process.env.PUBLIC_URL}/assets/defaulticon.webp`;

const RecentFiles = ({
  filteredData,
  loading,
  select,
  setSelect,
  showMultiFileOptions,
  setShowMultiFileOptions,
  removeFile,
  value,
  updateFiles,
  deptName,
  setFile,
  folderId = undefined,
}) => {
  const location = useLocation();
  const { darkMode } = useDarkMode();
  const [selectedFileInfo, setSelectedFileInfo] = useState({
    name: "",
    size: "",
    id: "",
    owner: "",
    profileUrl: "",
    lastUpdate: "",
    mimetype: "",
    download_url: "",
    drive_link: "",
    drive_file_id: "",
    versionId: "",
    hasPassword: false,
    password: "",
  });
  //debug for deleted file
  // console.log("Deleted Value: ",deletedFile);
  const [loading2, setLoading] = useState(true);
  const [sharedFileInfo, setSharedFileInfo] = useState({});
  const [isFileViewOpen, setIsFileViewOpen] = useState(false);
  const [isFileInfoOpen, setIsFileInfoOpen] = useState(false);
  const [fileAuthenticated, setFileAuthenticated] = useState(false);

  const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [checkPasswordLoading, setCheckPasswordLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");

  // const [anchorEl, setAnchorEl] = useState(null);
  const context = useContext(fileContext);
  const {
    anchorEl,
    setAnchorEl,
    updateFilesState,
    updateDepartmentFiles,
    setOpenInChat,
  } = context;
  const [menuFile, setMenuFile] = useState({});
  const [selectedFiles, setSelectedFiles] = useState([]);
  const profileData = useAuth((state) => state.profileData);
  // const [loading, setLoading] = useState(true);
  const [recycleFiles, setRecycleFiles] = useState([]);

  const open = Boolean(anchorEl);
  console.log({ recentFIlesDATA: filteredData });
  const recentBgColor = ["#FFF6F6", "#FFF6FF", "#F6FFF6", "#F6F7FF", "#FFFFF6"];

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getSharedFileInfo = async (fileId) => {
    try {
      const info = await api.get(`/file/sharedFileInfo/${fileId}/`);
      setSharedFileInfo(info.data);
      console.log("Shared File Info", info.data);
    } catch (error) {
      console.log(error);
    }
  };

  const openDrawer = (
    fileName,
    fileSize,
    fileId,
    owner,
    profilePic,
    lastUpdate,
    mimetype,
    download_url,
    isLocked,
    drive_link,
    drive_file_id,
    versionId,
    hasPassword,
    password,
    versions,
    currVersion
  ) => {
    getSharedFileInfo(fileId);

    const fileInfo = {
      name: fileName,
      size: fileSize,
      id: fileId,
      owner: owner,
      ownerProfileUrl: profilePic,
      lastUpdate: lastUpdate,
      mimetype: mimetype,
      download_url: download_url,
      drive_link: drive_link,
      drive_file_id: drive_file_id,
      versionId: versionId,
      hasPassword: hasPassword,
      password: password,
      versions: versions,
      currVersion: currVersion,
    };

    setSelectedFileInfo(fileInfo);

    if (isLocked) {
      toast.error("File has been locked by organization admin!");
    } else if (location.pathname === "/chat") {
      setOpenInChat(true);
      setFile(fileInfo);
    } else {
      setIsFileViewOpen(true);
    }
  };

  const closeDrawer = () => {
    setIsFileViewOpen(false);
  };

  const openFileInfoDrawer = (
    fileName,
    fileSize,
    fileId,
    owner,
    profilePic,
    lastUpdate,
    mimetype,
    download_url,
    isLocked,
    drive_link,
    drive_file_id,
    hasPassword,
    password
  ) => {
    getSharedFileInfo(fileId);
    setSelectedFileInfo({
      name: fileName,
      size: fileSize,
      id: fileId,
      owner: owner,
      ownerProfileUrl: profilePic,
      lastUpdate: lastUpdate,
      mimetype: mimetype,
      download_url: download_url,
      isLocked: isLocked,
      drive_link: drive_link,
      drive_file_id: drive_file_id,
      hasPassword: hasPassword,
      password: password,
    });

    setIsFileInfoOpen(true);
  };

  const closeFileInfoDrawer = () => {
    setIsFileInfoOpen(false);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
    // console.log("fileName", fileName);
  };

  const getIconByMimeType = (mimeType) => {
    return fileIcons[mimeType] || DefaultIcon;
  };

  const handleMultiFileSelect = (file) => {
    console.log(file);

    if (!file.isLocked) {
      if (selectedFiles.includes(file)) {
        const newSelectedFiles = selectedFiles.filter(
          (selectedFile) => selectedFile.id !== file.id
        );
        setSelectedFiles(newSelectedFiles);
      } else {
        setSelectedFiles([...selectedFiles, file]);
      }
    } else {
      toast.error("You cannot perform actions on locked files!");
    }
  };

  const handleLockChange = async (file) => {
    if (profileData.role_priv !== "employee") {
      const id = file.id;
      const res = await api.patch(`/file/change-lock-status/${id}/`, {
        is_locked: !file?.isLocked,
        folder_id: folderId,
      });
    }

    updateUI("isLocked", file, !file?.isLocked);
  };

  const handlePinChange = async (file) => {
    const id = file.id;
    const res = await api.patch(`/file/change-pin-status/${id}/`, {
      is_pinned: !file.isPinned,
      folder_id: folderId,
    });

    updateUI("isPinned", file, !file?.isPinned);
  };

  const updateUI = (changedAttr, file, newVal) => {
    const curr_location = location?.pathname.split("/")[1];

    switch (curr_location) {
      case "dashboard":
        updateFilesState(value);
        break;
      case "filesInsideFolder":
        updateFiles(file, changedAttr, newVal);
        break;
      case "department":
        updateDepartmentFiles(deptName);
        break;
      default:
        console.log("UNEXPECTED INPUT");
    }
  };

  useEffect(() => {
    if (!select) {
      setSelectedFiles([]);
    }
  }, [select]);

  useEffect(() => {
    if (selectedFiles.length > 0 && !showMultiFileOptions) {
      setShowMultiFileOptions(true);
    }

    if (showMultiFileOptions && selectedFiles.length === 0) {
      setShowMultiFileOptions(false);
      setSelect(false);
    }

    context.setSelectedFiles(selectedFiles);
  }, [selectedFiles]);

  // Extract file IDs from recycleFileMap
  const recycleFileIds = new Set(recycleFiles.map((file) => file.fileId));

  // Log the recycleFiles to verify the extraction
  console.log("Recycle File IDs:", Array.from(recycleFiles));

  console.log("All Files IDs:", filteredData);

  // Filter files to exclude those in the recycleFileMap
  const filteredFiles = filteredData.filter(
    (file) => !recycleFileIds.has(file.id)
  );

  useEffect(() => {
    const fetchRecycleBinData = async () => {
      try {
        const response = await api.get("/file/recycle-bin-file"); // Adjust the endpoint to match your API

        console.log("Recycle Bin Files:", response.data);
        if (response && response.data) {
          // Assuming response.data is an array of deleted files
          const deletedFiles = response.data;
          setRecycleFiles(deletedFiles); // Set the fetched recycle bin files to state
        }
      } catch (error) {
        console.error("Error fetching recycle bin files:", error);
      } finally {
        setLoading(false); // Set loading to false after fetching data
      }
    };

    fetchRecycleBinData();
  }, []);
  console.log({ folderFiles: filteredFiles });
  console.log({ tabeMenufile: menuFile, profileData });

  const hexToRgba = (hex, alpha) => {
    let r = 0,
      g = 0,
      b = 0;

    // Remove "#" if present
    if (hex.length === 4) {
      r = parseInt(hex[1] + hex[1], 16);
      g = parseInt(hex[2] + hex[2], 16);
      b = parseInt(hex[3] + hex[3], 16);
    } else if (hex.length === 7) {
      r = parseInt(hex[1] + hex[2], 16);
      g = parseInt(hex[3] + hex[4], 16);
      b = parseInt(hex[5] + hex[6], 16);
    }

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  };

  const handlePasswordCheck = async () => {
    setCheckPasswordLoading(true);
    try {
      const token = await auth.currentUser.getIdToken();

      const res = await api.post("/file/verify_password/", {
        filePassword: selectedFile.password,
        userPassword: password,
        idToken: token,
      });

      if (res.data.success) {
        toast.success(res.data.message);
        setFileAuthenticated(true);
        setPasswordDialogOpen(false);
        openDrawer(
          selectedFile.name,
          selectedFile.size,
          selectedFile.id,
          selectedFile.owner,
          selectedFile.profilePic,
          selectedFile.lastUpdate,
          selectedFile.mimetype,
          selectedFile.downloadUrl,
          selectedFile.isLocked,
          selectedFile.drive_link,
          selectedFile.drive_file_id,
          selectedFile.versionId,
          selectedFile.hasPassword,
          selectedFile.password,
          selectedFile.versions,
          selectedFile.currVersion
        );

        setPassword("");
        setFileAuthenticated(false);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    } finally {
      setCheckPasswordLoading(false);
    }
  };

  const openPasswordDialog = (file) => {
    setSelectedFile(file);
    setPasswordDialogOpen(true);
  };

  console.log({
    passwordDialogOpen,
    fileAuthenticated,
    password,
    selectedFile,
  });

  return (
    <div>
      {!fileAuthenticated && (
        <Dialog
          open={passwordDialogOpen}
          onClose={() => setPasswordDialogOpen(false)}
          maxWidth="sm"
          sx={{
            borderRadius: "5px",
            zIndex: 9999,
          }}
        >
          <DialogTitle>
            This file is password protected. Please enter the password to view
            the file.
          </DialogTitle>
          <DialogContent
            style={{
              backgroundColor: "#F7F8FA",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "20px",
            }}
          >
            <TextField
              autoFocus
              id="password"
              name="password"
              type="password"
              value={password}
              variant="outlined"
              label="Password"
              sx={{
                width: "70%",
              }}
              color="secondary"
              placeholder="Enter password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </DialogContent>
          <DialogActions>
            <button
              className={
                "flex items-center gap-2 disabled:opacity-60 disabled:cursor-not-allowed px-4 py-1 rounded-lg shadow-sm text-black border border-gray-700 bg-gray-50 hover:bg-[#D1293Dda] hover:text-white hover:border-[#D1293D]"
              }
              onClick={() => {
                setPassword("");
                setPasswordDialogOpen(false);
                setSelectedFile(null);
              }}
              color="primary"
            >
              Close
            </button>
            <button
              className={
                "flex items-center gap-2 disabled:opacity-60 disabled:cursor-not-allowed px-4 py-1 rounded-lg shadow-sm text-white bg-[#5E5ADB] hover:bg-[#6e6bd0da] gap-x-2"
              }
              onClick={handlePasswordCheck}
              disabled={checkPasswordLoading}
            >
              Submit
              {checkPasswordLoading && (
                <CircularProgress
                  style={{ color: "#fff", height: 15, width: 15 }}
                />
              )}
            </button>
          </DialogActions>
          <Toaster position="bottom-left" reverseOrder={false} />
        </Dialog>
      )}
      <div
        className={`grid ${location.pathname === "/chat" ? "grid-cols-2" : "grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5"}  gap-4 ${
          darkMode ? "text-gray-200" : "text-gray-600"
        }`}
      >
        {loading
          ? Array.from({ length: 5 }).map((_, index) => (
              <div
                key={index}
                className="border border-gray-200 p-3 rounded-2xl shadow-sm"
              >
                <span className="flex flex-row justify-between items-center px-1 my-1">
                  <Skeleton variant="circular" height={22} width={22} />
                  <img src={threeDots} alt="..." height={25} width={25} />
                </span>

                <span className="">
                  <Skeleton variant="rounded" height={60} width="100%" />
                  <span className="font-semibold">
                    <Skeleton height={22} width="100%" />
                  </span>
                </span>

                <span>
                  <span className="flex flex-row justify-between items-center my-1 px-1">
                    <span className="w-full text-sm text-gray-500 font-light">
                      <Skeleton height={18} width="70%" />
                    </span>
                    <Skeleton variant="circular" height={22} width={22} />
                  </span>
                </span>
              </div>
            ))
          : filteredFiles.map((file, index) => {
              const color = file.color || "#3498db"; // Default color if none provided

              const backgroundColor = hexToRgba(color, 0.1); // Lighten intensity with 0.1 alpha
              return (
                <div
                  key={index}
                  className={`border border-[#ffa9ff46] p-3  rounded-[16px] cursor-pointer relative ${location.pathname === "/chat" ? "h-48" : "auto"}`}
                  style={{
                    // backgroundColor: file.color || `#f9f9f9`,
                    backgroundImage: FileBg({
                      gradientColors: [
                        file.color,
                        file.color,
                        file.color,
                        file.color,
                      ], // Custom dynamic colors
                    }),
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                    backgroundRepeat: "no-repeat",
                    backgroundBlendMode: "lighten",
                  }}
                >
                  <div
                    className="w-full h-full absolute left-0 top-0 items-center justify-center rounded-[16px]"
                    onClick={() => handleMultiFileSelect(file)}
                    style={{
                      backgroundColor: "rgba(255, 255, 255, 0.5)",
                      display:
                        selectedFiles.includes(file) && select
                          ? "flex"
                          : "none",
                    }}
                  >
                    <div className="py-3 px-3 rounded-full bg-green-400">
                      <DoneIcon />
                    </div>
                  </div>
                  <span className="flex  justify-between items-center">
                    <button
                      onClick={() =>
                        select
                          ? handleMultiFileSelect(file)
                          : openFileInfoDrawer(
                              file.name,
                              file.size,
                              file.id,
                              file.owner,
                              file.profilePic,
                              file.lastUpdate,
                              file.mimetype,
                              file.downloadUrl,
                              file.isLocked,
                              file.drive_link,
                              file.drive_file_id,
                              file.hasPassword,
                              file.password
                            )
                      }
                    >
                      <b className="text-gray-500 font-serif text-xs border-2 border-gray-500 rounded-full px-[5px] mx-1">
                        i
                      </b>
                    </button>

                    <span style={{ fontSize: 20 }} className="flex">
                      <div onClick={() => handleLockChange(file)}>
                        {file?.isLocked ? (
                          <LockIcon fontSize="inherit" />
                        ) : (
                          <LockOpenIcon fontSize="inherit" />
                        )}
                      </div>
                      <div
                        onClick={() => handlePinChange(file)}
                        className="ml-1"
                      >
                        {file?.isPinned ? (
                          <PushPinIcon fontSize="inherit" />
                        ) : (
                          <PushPinOutlinedIcon fontSize="inherit" />
                        )}
                      </div>
                      {(file?.owner === profileData?.id ||
                        profileData?.role_priv === "org_admin") && (
                        <button
                          className=""
                          onClick={(event) => {
                            handleMenuClick(event);
                            setMenuFile(file);
                          }}
                        >
                          <img src={threeDots} height={25} width={25} alt="" />
                        </button>
                      )}
                      {(file?.owner === profileData?.id ||
                        profileData?.role_priv === "org_admin") && (
                        <Menu
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                          PaperProps={{
                            style: {
                              border: "1px solid [#11182626]",
                              boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.1)",
                              borderRadius: "6px",
                            },
                          }}
                        >
                          <MenuItem
                            // onClick={handleClose}

                            style={{ padding: "0px 10px" }}
                          >
                            <FileShare
                              menuFile={menuFile}
                              closeMenu={handleClose}
                            />
                          </MenuItem>

                          <MenuItem
                            style={{ padding: "0px 10px", color: "#D1293D" }}
                          >
                            <RecycleBinConfirmation //calling the recycle bin instead of directly deleting
                              fileName={menuFile.name}
                              owner={menuFile.owner}
                              id={menuFile.id}
                              remove={removeFile}
                              size={menuFile.size}
                              mimeType={menuFile.mimetype}
                              profilePic={menuFile.profilePic}
                              downloadUrl={menuFile.downloadUrl}
                              currVersion={menuFile.currVersion}
                              versions={menuFile.versions}
                            />
                          </MenuItem>
                          <MenuItem style={{ padding: "0px 10px" }}>
                            <RenameFile
                              file={menuFile}
                              closeMenu={handleClose}
                            />
                          </MenuItem>
                          {profileData.id === menuFile.owner && (
                            <MenuItem style={{ padding: "0px 10px" }}>
                              <SetPassword file={menuFile} />
                            </MenuItem>
                          )}
                          {profileData.id === menuFile.owner && (
                            <MenuItem style={{ padding: "0px 10px" }}>
                              <ShowVersions
                                file={menuFile}
                                openFile={(newId) => {
                                  setSelectedFileInfo((prevData) => ({
                                    ...prevData,
                                    currVersion: newId,
                                  }));
                                  setIsFileViewOpen(true);
                                  handleClose();
                                }}
                                closeMenu={handleClose}
                              />
                            </MenuItem>
                          )}
                        </Menu>
                      )}
                    </span>
                  </span>

                  <div
                    onClick={() =>
                      select
                        ? handleMultiFileSelect(file)
                        : fileAuthenticated || !file.hasPassword
                          ? openDrawer(
                              file.name,
                              file.size,
                              file.id,
                              file.owner,
                              file.profilePic,
                              file.lastUpdate,
                              file.mimetype,
                              file.downloadUrl,
                              file.isLocked,
                              file.drive_link,
                              file.drive_file_id,
                              file.versionId,
                              file.hasPassword,
                              file.password,
                              file.versions,
                              file.currVersion
                            )
                          : openPasswordDialog(file)
                    }
                  >
                    <span className="flex justify-center items-center">
                      {/* Use the getIconByExtension function to determine the correct SVG */}
                      <img
                        src={getIconByMimeType(file.mimetype)}
                        alt="File Preview"
                        className={`rounded-md my-4 ${location.pathname === "/chat" ? "h-8" : "auto"}`}
                      />
                    </span>
                    <span>
                      <h5 className="font-semibold line-clamp-1 text-gray-700 text-sm mb-1">
                        {file?.name?.split("_TS=")[0]}
                      </h5>
                      <span className="flex flex-row justify-between items-center">
                        <span>
                          <h6 className="font-semibold">
                            {/* <span className="text-xs font-bold text-[#676767]">
                            File size:
                          </span>{" "} */}
                            <span className="text-xs font-bold text-[10px] text-[#676767]">
                              {file.size}
                            </span>
                          </h6>
                        </span>

                        <Avatar
                          src={file.profilePic}
                          alt="owner pic"
                          sx={{
                            width: 24,
                            height: 24,
                            border: "1px solid silver",
                          }}
                          className={`${darkMode && "border border-white "}`}
                        />
                      </span>
                    </span>
                  </div>
                </div>
              );
            })}
      </div>
      {isFileInfoOpen && (
        <FileInfo
          fileInfo={selectedFileInfo}
          closeDrawer={closeFileInfoDrawer}
          sharedFileInfo={sharedFileInfo}
        />
      )}
      {isFileViewOpen && (
        <FileView
          fileInfo={selectedFileInfo}
          closeDrawer={closeDrawer}
          sharedFileInfo={sharedFileInfo}
        />
      )}
    </div>
  );
};

export default RecentFiles;

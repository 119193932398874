import React, { useContext, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { supabase } from "../helper/supabaseClient";
import secureLocalStorage from "react-secure-storage";
import fileContext from "../context/fileContext";
import { useParams } from "react-router-dom";
import { doc, setDoc, getFirestore } from "firebase/firestore";
// import { getFirestore } from "firebase/firestore";
import { deleteObject, getStorage, ref } from "firebase/storage";
import { api } from "../utils/axios-instance";
import axios from "axios";
import { auth } from "../helper/firebaseClient";

// images from public folder
const Danger = `${process.env.PUBLIC_URL}/assets/danger.webp`;

const RecycleBinConfirmation = ({
  fileName,
  owner,
  id,
  remove,
  size,
  mimeType,
  profilePic,
  downloadUrl,
  currVersion,
  versions
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // "success" or "error"
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const { deptName } = useParams();
  const context = useContext(fileContext);
  const { removeFile, setAnchorEl, updateDepartmentFiles } = context;

  console.log(fileName, owner, id);

  const openDialog = () => {
    setIsOpen(true);
  };

  const closeDialog = () => {
    setIsOpen(false);
    setAnchorEl(null);
  };
  const [recycleFileMap, setRecycleFileMap] = useState(new Map()); //map

  //Firebase used for Recycle Bin through Django
  const handleRecycleBin = async () => {
    let profileData = JSON.parse(secureLocalStorage.getItem("profileData"));
    const token = await auth.currentUser.getIdToken();

    console.log("Token: ", token);

    if (profileData.id === owner || profileData.role_priv === "org_admin") {
      try {
        const deleted = 1;

        // Create the payload for the backend
        const recycleData = {
          fileId: id,
          fileName: fileName,
          owner: owner,
          deleted: deleted,
          idToken: token,
          size: size,
          mimeType: mimeType,
          profilePic: profilePic,
          downloadUrl: downloadUrl,
          currVersion: currVersion,
          versions: versions
        };

        console.log(
          "File before being moved to Recycle Bin via backend:",
          recycleData
        );

        // Make the API POST request to your Django backend
        const res = await api.post(`/file/recycle-file/${id}/`, recycleData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        console.log(
          "File after being moved to Recycle Bin via backend:",
          res.data
        );

        // Update the file list in context/state to remove the deleted file
        removeFile(id); // Assuming removeFile updates the list in context

        // Display success message
        setSnackbarSeverity("success");
        setSnackbarMessage("File successfully moved to Recycle Bin.");
        setSnackbarOpen(true);

        // Delay closing the dialog to ensure Snackbar appears
        setTimeout(() => {
          closeDialog();
        }, 3000); // Adjust the delay as needed (3 seconds in this case)
      } catch (error) {
        console.error("Error occurred while deleting the file:", error.message);
        setSnackbarSeverity("error");
        setSnackbarMessage("Error deleting the file.");
        setSnackbarOpen(true);
      }
    } else {
      setSnackbarSeverity("error");
      setSnackbarMessage("You are not the owner of the file.");
      setSnackbarOpen(true);
      setTimeout(() => {
        closeDialog();
      }, 3000);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);

    // Close the dialog after 3 seconds
  };

  return (
    <div className="">
      <button onClick={openDialog} className="text-[#D1293D]">
        Delete File
      </button>

      <Dialog
        open={isOpen}
        onClose={closeDialog}
        PaperProps={{
          style: {
            borderRadius: "5px",
          },
        }}
      >
        {/* <DialogTitle>Confirm Delete</DialogTitle> */}
        <DialogContent
          style={{
            backgroundColor: "#FEF2F2",
          }}
        >
          {/* Recycle Bin */}
          <div className="my-2 p-3 flex flex-col justify-center items-center gap-6">
            <img src={Danger} alt="." />
            <h2 className="text-2xl font-semibold text-gray-700">
              Are you sure?
            </h2>
            <div className="text-center">
              <p>
                This action will delete the{" "}
                <strong className="hover:underline">
                  {fileName?.split("_TS=")[0]}
                </strong>{" "}
                file.
              </p>
              <p>Your File will be in Recycle Bin</p>
            </div>

            <div className="flex flex-row justify-center items-center gap-2">
              <button
                className="px-4 py-1 rounded-lg shadow-sm bg-red-500 text-white"
                onClick={handleRecycleBin}
              >
                Confirm
              </button>
              <button
                className="px-4 py-1 mx-2 rounded-lg shadow-sm border border-gray-300"
                onClick={closeDialog}
                color="primary"
              >
                Cancel
              </button>
            </div>

            <Snackbar
              open={snackbarOpen}
              autoHideDuration={6000}
              onClose={handleSnackbarClose}
            >
              <MuiAlert
                elevation={6}
                variant="filled"
                onClose={handleSnackbarClose}
                severity={snackbarSeverity}
              >
                {snackbarMessage}
              </MuiAlert>
            </Snackbar>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default RecycleBinConfirmation;
